import React from "react";

type Props = {
    children:any;
    onAction:() => void;
}

const StandardButton = ({children, onAction}:Props) => (
    <button
        className={`w-full h-full bg-gcc-bright-orange hover:bg-gcc-hover rounded-full text-center font-bold text-white`}
        onClick={onAction}
    >
        {children}
    </button>
);

export default StandardButton;