import React from 'react';

const FullWidthImageSlice = (props:any) => {

    const primary = props && props.data && props.data.primary;

    if(primary) {

        const {show_entire_image, image} = primary;
        const imageURL = image && image.url;
  
        if(imageURL)
            return show_entire_image?
                <img src={imageURL} alt='Full Width Image' /> :
                <div
                    className='full-width-image h-64 bg-cover bg-center'
                    style={{backgroundImage: `url('${imageURL}')`}}
                />;
    }

    return null;

}

export default FullWidthImageSlice;