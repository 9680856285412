import React, { Fragment } from "react";

export default function ImageSlice({data}:any) {

    const primary = data && data.primary;
    const items = data && data.items;

    if(primary) {
        const {mobile_image, desktop_image} = primary;
        return (
            <div className="image-component">
                <div className="image-component__image-container">
                    {mobile_image && <img className="image-component__image-mobile md:hidden" alt={mobile_image.alt} src={mobile_image.url} />}
                    {
                        desktop_image &&
                        <img
                            alt={desktop_image.alt} 
                            className="hidden md:block mx-auto" style={{maxWidth: '80vw'}}
                            src={desktop_image.url}
                        />
                    }
                    {
                        items &&
                        items.map((
                            {label_text, mobile_label_x, mobile_label_y, desktop_label_x, desktop_label_y}:any,
                            index:number) => (
                            <Fragment key={`label-${index}`}>
                                {
                                    mobile_label_x && mobile_label_y?
                                    <div
                                        className="image-component__label image-component__label--mobile"
                                        style={{left: `${mobile_label_x}%`, top: `${mobile_label_y}%`}}
                                    >
                                        {label_text}
                                    </div> :
                                    null
                                }
                                <div
                                    className="image-component__label image-component__label--desktop"
                                    style={{left: `${desktop_label_x}%`, top: `${desktop_label_y}%`}}
                                >
                                    {label_text}
                                </div>
                            </Fragment>
                        ))
                    }
                </div>
            </div>
        );
    }

    return null;
}