import React from "react";
import { PrismicLink } from "@prismicio/react";

type Props = {
    children:any;
    document?:any;
    href?:any;
}

const StandardLinkButton = ({children, document, href}:Props) => {
    if (href) {
        return (
            <PrismicLink className={`flex items-center justify-center w-full h-full bg-gcc-bright-orange hover:bg-gcc-hover rounded-full text-center font-bold text-white`} href={href}>
                {children}
            </PrismicLink>
        );
    } 
    if (document)  {
        return (
            <PrismicLink className={`flex items-center justify-center w-full h-full bg-gcc-bright-orange hover:bg-gcc-hover rounded-full text-center font-bold text-white`} field={document}>
                {children}
            </PrismicLink>
        );
    }
    return null;
};

export default StandardLinkButton;