import React from "react";

export default function ParagraphSlice(props:any) {

    const data = props.data;
    const items = data? data.items : null;

    if(items) {
        return (
            <div className="container mx-auto pb-20">
            {
                items &&
                items.map((item:any, index:number) => (
                    <div
                        className={`pt-8 md:pt-12 px-8 md:px-4 pb-4 md:pb-4`}
                        key={`item-${index}`}
                    >
                        <h2 className="font-bold mb-1">{item.title[0].text}</h2>
                        <p className="">{item.rich_text[0].text}</p>
                    </div>
                ))
            }
            </div> 
        );
    }

    return null;
}