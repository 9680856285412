import React from "react";
import Plyr from "plyr-react"

type Props = {
    embedCode?: string | null;
    mediaEmbed?: string | null;
    handleCloseRequest: () => void;
}

const VideoModal = ({ embedCode, mediaEmbed, handleCloseRequest }: Props) => {

    return (
        <div className='video-modal' onClick={() => handleCloseRequest()}>
            <div className='video-modal__inner relative'>
                <div className="cursor-pointer z-10 mb-4"><img className="h-8 w-8" src="/images/close-white.svg" alt='Close'></img></div>
                {embedCode &&
                    <div className="video-modal__player" dangerouslySetInnerHTML={{ __html: embedCode }} />
                }
                {mediaEmbed &&
                    <div className="video-modal__player" onClick={(e: any) => {e.stopPropagation()}}>
                        <Plyr source={{
                            type: "video",
                            sources: [
                                {
                                    src: mediaEmbed,
                                }
                            ]
                        }} />
                    </div>
                }
            </div>
        </div>
    )
};

export default VideoModal;

