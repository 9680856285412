import React from "react";
import StandardButton from "./StandardButton";

type Props = {
    onAccept:() => void;
}

const CookiePanel = ({onAccept}:Props) => {
    return (
        <div
            className="fixed top-0 left-0 w-screen pointer-events-none flex items-end"
            style={{height: `${window.innerHeight}px`}}
        >
            <div className="w-full pointer-events-auto bg-gcc-dark p-5 md:pt-4 md:px-8 md:flex md:justify-between md:items-center text-sm md:text-base text-white">
                <p>This site uses cookies to deliver its services and to analyze traffic.</p>
                <div className="flex justify-between items-center text-base-sm">
                    <a className="text-gcc-bright-orange underline" href="https://www.homedepot.com/privacy/privacy-and-security-statement" target="_blank" rel="noopener noreferrer">Learn more</a>
                    <div className="md:ml-4 w-32 h-10">
                        <StandardButton onAction={onAccept}>Accept</StandardButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CookiePanel;