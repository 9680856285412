import React, { useMemo, useState, useEffect } from "react";
import PageSelector from "../PageSelector";

import * as fetchJsonp from 'fetch-jsonp';

const DropDown = ({
                      options,
                      selectedOptionIndex,
                      expand,
                      onExpandAction,
                      onSelectAction
                  }:{
    options:string[],
    selectedOptionIndex:number,
    expand:boolean,
    onExpandAction:() => void,
    onSelectAction:(index:number) => void
}) =>
    <>
        {/* <div
            className=
                "mx-auto h-14 border border-gcc-light-blue-gray rounded pl-4 pr-6 flex justify-between items-center"
            style={{maxWidth: '428px'}}
            onClick={onExpandAction}
        >
            <p>{`${selectedOptionIndex === -1? 'Teams' : options[selectedOptionIndex]}`}</p>
            <img className="w-3 h-6 transform rotate-90" src='/images/arrow-right-orange.svg' alt='' />
        </div> */}
        {
            expand?
                <ul
                    className="mx-auto border border-gcc-light-blue-gray rounded bg-gcc-medium-light-gray"
                    style={{maxWidth: '428px'}}
                >
                    {
                        [...options, 'All'].map((option, index) =>
                            <li
                                className="px-4 py-2 hover:bg-gcc-orange"
                                onClick={() => onSelectAction(index === options.length? -1 : index)}
                                key={`item-${index}`}
                            >
                                {option}
                            </li>
                        )
                    }
                </ul> :
                null
        }
    </>;

// How many items per page
const MAX_DISPLAY_ITEMS = 10;

const JobListSlice = ({data}:any) => {

    const {items} = data;

    // Define state items to hold API response
    const [jobs, setJobs] = useState([]);

    // Set the default number of pages
    const [numPages, setNumpages] = useState(1);

    // Set the page index to 0
    const [pageIndex, setPageIndex] = useState(0);

    const [expandDropDown, setExpandDropDown] = useState(false);

    const [teamIndex, setTeamIndex] = useState(-1);

    // We need two computed indexes to slice the array of results
    const startIndex = useMemo(() => pageIndex * MAX_DISPLAY_ITEMS, [pageIndex]);
    const endIndex = useMemo(() => startIndex + MAX_DISPLAY_ITEMS, [startIndex]);
    const teamOptions:string[] = useMemo(() => items && items.map(({team_option}:any) => team_option), [items])

    // On page load use JSONP to fetch the resullts from the API
    useEffect(() => {
        /* @ts-ignore */
        fetchJsonp('https://jobsapi-google.m-cloud.io/api/job/search?pageSize=100&offset=0&companyName=companies%2F8454851f-07b7-4e4c-9b5f-00e0ffbfcb09&customAttributeFilter=store_id%3D%222140%22 AND is_internal%3D%22External%22&orderBy=postingPublishTime%20desc')
            .then(function(response: any) {
                return response.json()
            }).then(function(json: any) {

            let jobs = json.searchResults;

            if(teamIndex !== -1) {
                jobs = jobs.filter(
                    ({job}:any) =>
                        (job.primary_category as string)
                            .toLowerCase()
                            .includes(teamOptions[teamIndex].toLowerCase())
                )
            }

            // Set the results into state
            setJobs(jobs);
            // Calculate the total amount of pages
            let numPages = Math.ceil(json.searchResults.length / MAX_DISPLAY_ITEMS)
            // Set the total into state
            setNumpages(numPages)
        })
    }, [teamOptions, teamIndex]);

    if(jobs) {

        return (
            <div className="relative pt-8 md:pt-16 px-4 md:px-3/25 pb-7 md:pb-14">
                {
                    items &&
                    <div className="absolute left-0 -top-25 w-full px-4 cursor-pointer">
                        <DropDown
                            options={teamOptions}
                            selectedOptionIndex={teamIndex}
                            expand={expandDropDown}
                            onExpandAction={() => setExpandDropDown(!expandDropDown)}
                            onSelectAction={(index) => {
                                setTeamIndex(index);
                                setExpandDropDown(false);
                            }}
                        />
                    </div>
                }
                {
                    jobs && jobs
                        .slice(startIndex, endIndex)
                        .map(({job}:any, index:number) => (
                            <a
                                className="mb-2 h-32 md:h-25 border border-solid border-gcc-dark border-opacity-20 rounded-lg py-2.5 md:py-4 px-4 md:px-8 flex flex-col justify-between cursor-pointer hover:text-gcc-bright-orange"
                                href={job.url}
                                target="_blank"
                                key={`item-${index}`}
                            >
                                <h1 className="text-base md:text-xl2 font-extrabold leading-6">{job.title}</h1>
                                <div className="flex justify-between text-base md:text-lg">
                                    <p className="text-gcc-bright-orange">{job.primary_category}</p>
                                    <p className="md:relative md:-top-10">{job.compliment}</p>
                                </div>
                            </a>
                        ))
                }
                {
                    // If the amount of pages is greater than 1 show the pagination
                    // Remember that pageIndex is 0 not 1
                    numPages > 1?
                        <PageSelector
                            pageNum={pageIndex + 1}
                            numPages={numPages}
                            onPrevAction={() => setPageIndex(pageIndex === 0 ? 0 : pageIndex - 1)} // Don't go below 1
                            onNextAction={() => setPageIndex((pageIndex + 1) === numPages ? numPages - 1 : pageIndex + 1)} // Don't go above the total amount of pages
                        /> :
                        null
                }
            </div>
        );

    }

    return null;

}

export default JobListSlice;