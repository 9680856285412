import React from "react";

type Props = {
    pageNum:number;
    numPages:number;
    onPrevAction:() => void;
    onNextAction:() => void;
};

const PageSelector = ({pageNum, numPages, onPrevAction, onNextAction}:Props) => (
    <div className="mt-8 md:mt-12 flex justify-center items-center">
        <div className="w-4 h-4 bg-center bg-contain arrow-left cursor-pointer bg-no-repeat" onClick={onPrevAction}></div>
        <p className="w-28 text-center text-sm md:text-base">
            <span className="mr-1 font-extrabold">{pageNum}</span> of <span className="ml-1">{numPages}</span>
        </p>
        <div className="w-4 h-4 bg-center bg-contain arrow-right cursor-pointer bg-no-repeat" onClick={onNextAction}></div>
    </div>
);

export default PageSelector;