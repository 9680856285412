import React from "react";
import { textItemsFromField } from "../../PrismicUtils";
import PrismicCTAButton from "../PrismicCTAButton";

export default function TextBannerSlice({data}:any) {
    
    const {primary, items} = data;

    if(primary && items) {

        const {overview_title, overview, related_link_label, related_link, banner_heading, banner_supporting_text}
            = primary;

        return (
            <div className="md:mx-24 py-12 md:flex md:flex-row-reverse">
                <div className="md:flex-1 px-7 md:pl-28">
                    {
                        textItemsFromField(overview_title, (text, index) =>
                            <h1 className="hidden md:block text-4xl-2" key={`item-${index}`}>{text}</h1>)
                    }
                    <p className="md:mt-4 md:leading-8">{overview}</p>
                    {
                        related_link_label &&
                        <PrismicCTAButton document={related_link} label={related_link_label}></PrismicCTAButton>
                    }
                </div>
                <div
                    className="mt-14 md:mt-0 mx-3 md:flex-1 rounded-3xl bg-gcc-medium-light-gray pt-8 px-3 pb-7 text-center"
                >
                    {
                        textItemsFromField(banner_heading, (text, index) =>
                            <h1
                                className="text-gcc-bright-orange md:uppercase md:tracking-wider"
                                key={`item-${index}`}
                            >
                                {text}
                            </h1>
                        )
                    }
                    <ul className="text-xl font-bold tracking-tight">
                        {
                            items.map(({banner_item}:any, index:number) =>
                                <li className="mt-2.5" key={`item-${index}`}>{banner_item}</li>)
                        }
                    </ul>
                    <p
                        className="mt-4 md:mx-auto text-sm leading-6 md:leading-normal opacity-70"
                        style={{maxWidth: '470px'}}
                    >
                        {banner_supporting_text}
                    </p>
                </div>
            </div>
        );
    }

    return null;

}