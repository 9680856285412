import { PrismicRichText } from "@prismicio/react";
import React from "react"
import PrismicCTAButton from "../PrismicCTAButton";

type Props = {
    data:any;
    index:number;
    startTextLeft:boolean;
    mobileSwapPaneOrder:boolean;
};

function coordsStyleFromText(text:string) {
    if(text) {
        const coords = text.split(',');
        return {left: coords[0], top: coords[1]};
    }
}

const FeatureRowsSlice = ({data, index, startTextLeft, mobileSwapPaneOrder}:Props) => {

    return (
        <div className={`feature md:first:pt-8 ${mobileSwapPaneOrder? 'pb-6' : 'pb-24'} ${data.overlay_above ? 'mt-0 md:-mt-32' : ''}`}>
          {data && (
            <div className={`group flex ${mobileSwapPaneOrder? 'flex-col-reverse' : 'flex-col'} md:flex-row md:${((index + (startTextLeft? 1 : 0)) % 2 === 0)? 'flex-row' : 'flex-row-reverse'}`}>
                {data.mobile_image.url &&
                    <div className={`main-image-container ${mobileSwapPaneOrder? 'mt-8 md:mt-0' : ''} md:w-screen-45 relative`}>
                        <img alt={data.c03_image.alt} className='hidden md:block md:flex-1 md:w-full' src={data.c03_image.url} />
                        <img alt={data.mobile_image.alt} className='block md:hidden' src={data.mobile_image.url} />
                        {
                            data.decoration_1.url &&
                            <img
                                className="absolute md:hidden"
                                style={coordsStyleFromText(data.mobile_decoration_position)}
                                src={data.decoration_1.url}
                                alt={data.decoration_1.alt}
                            />
                        }
                        {
                            data.decoration_1.url &&
                            <img
                                className="absolute hidden md:block"
                                style={coordsStyleFromText(data.desktop_decoration_1_position)}
                                src={data.decoration_1.url}
                                alt={data.decoration_1.alt}
                            />
                        }
                        {
                            data.decoration_2.url &&
                            <img
                                className="absolute hidden md:block"
                                style={coordsStyleFromText(data.desktop_decoration_2_position)}
                                src={data.decoration_2.url}
                                alt={data.decoration_2.alt}
                            />
                        }
                    </div>
                }
                <div className='text md:flex-1 px-5 md:pl-32 md:even:pl-16 md:pt-14 md:pr-24'>
                    {data.c03_name && data.c03_name.length?
                      data.c03_name.map((name:any, index:number) =>
                          <p className='small-title mt-8 md:mt-0 md:text-lg' key={index}>{name.text}</p>
                      ) :
                      <div className="h-8" />
                    }
                  <div>
                    {
                      data.c03_content &&
                      <div className='feature-body'>
                        <PrismicRichText field={data.c03_content} components={{
                            heading1: ({children, key}) =>
                                <h1
                                    className="md:mt-2.5 md:mb-5 text-2xl-2 md:text-4xl-2 leading-8 md:leading-normal"
                                    key={key}
                                >
                                    {children}
                                </h1>,
                            heading2: ({children, key}) => <h2 className="mt-4 md:mt-8 md:first:mt-4 font-bold text-2xl-2 leading-tight tracking-tight" key={key}>{children}</h2>,
                            paragraph: ({children, key}) => <p className="mt-2 md:mt-4 md:leading-7 mb-8" key={key}>{children}</p>,
                        }} />
                      </div>
                    }
                  </div>
                  {data.c03_button_link && data.c03_button_label[0] && (
                      <PrismicCTAButton document={data.c03_button_link} label={data.c03_button_label[0].text}></PrismicCTAButton>
                  )}
                </div>
            </div>
          )}
        </div>
    )
  }

export default FeatureRowsSlice;