import React from 'react';
import { usePrismicDocumentByUID } from '@prismicio/react'
import PageContent from '../components/PageContent';

const Innovation = () => {
    const [document] = usePrismicDocumentByUID('gcc_content_page', 'innovation')
    return <PageContent document={document} sectionClass='innovation' />
}

export default Innovation;
