import React from "react"
import { textItemsFromField } from "../../PrismicUtils";

import BenefitCard from "../BenefitCard";

const BenefitsSlice = (props:any) => {

    const {data} = props;
    const {items} = data;
    const {primary} = data;

    return (
      <div className='benefits bg-gcc-medium-light-gray-2 pt-9 md:pt-14 px-5 md:px-2/25 pb-16 md:text-center'>
            <div>
              {primary.c04_benefit_title &&
                primary.c04_benefit_title.map((title:any, index:number) => {
                    return (
                        <h1 className="text-2xl-2 md:text-4xl-2" key={index}>{title.text}</h1>
                    )
                })
              }
            </div>
            {
                textItemsFromField(
                    primary.c04_benefit_content,
                    (text, index) =>
                        <p
                            className="mt-2.5 md:mt-4 md:mx-auto md:leading-8"
                            style={{maxWidth: '885px'}}
                            key={`item-${index}`}
                        >
                            {text}
                        </p>
                )
            }
            <div className='cards mt-6 md:mt-12 md:flex md:flex-wrap md:justify-center'>
                {
                    items &&
                    items.map((item:any, index:number) => {
                        return (
                            <BenefitCard key={index} data={item}/>
                        )
                    })
                }
            </div>
      </div>
    )
  }

export default BenefitsSlice;