import React from 'react';
import { usePrismicDocumentByUID } from '@prismicio/react'
import PageContent from '../components/PageContent';

const Terms = () => {
    const [document] = usePrismicDocumentByUID('gcc_content_page', 'terms-and-conditions')
    return <PageContent document={document} sectionClass='terms-and-conditions' />
}

export default Terms;
