import { PrismicRichText } from "@prismicio/react";
import React, { Fragment as div, useRef } from "react";
import { textItemsFromField } from "../PrismicUtils";
import StandardLinkButton from "./StandardLinkButton";

type Props = {
    mainImageURL:string;
    videoEmbedSnippet:string | null;
    date:string;
    title:any[];
    intro:any[];
    themeColour:string;
    body?:any;
    related_link_label?:string;
    related_link_url?:any;
    style?:BlogEntryCardStyle;
};

export enum BlogEntryCardStyle {
    Normal,
    Highlight,
    Detail,
}

const MainImage =
    ({mainImageURL, videoEmbedSnippet, style, themeColour}:{mainImageURL:string | null, videoEmbedSnippet: string | null, style:BlogEntryCardStyle, themeColour:string}) => {

    const detail = style === BlogEntryCardStyle.Detail;
    const highlight = style === BlogEntryCardStyle.Highlight;
    const normal = style === BlogEntryCardStyle.Normal

    if(mainImageURL && !videoEmbedSnippet) {

        let heightClass = highlight? 'h-2' : (normal? 'h-4' : 'h-8 md:h-4');
        let roundedTop = highlight || normal;
        let roundedBottom = normal;
        let roundedDesktop = highlight || detail;

        return (
            <div className={
                `${
                    detail? 'md:rounded-lg mt-8' : 'md:mx-0 rounded-t-lg md:rounded-tr-none md:rounded-bl-lg'
                } ${
                     highlight? 'md:h-auto' : 'rounded-b-lg md:rounded-tr-lg'
                } ${
                    (highlight || normal)? 'md:flex md:flex-col md:justify-center' : ''
                } ${
                    normal? 'md:h-54' : ''
                }`}
                style={(themeColour && !detail && highlight)? {backgroundColor: themeColour} : undefined}
            >
                <img src={mainImageURL} alt='' className={`blog__card-image ${highlight? 'blog__card-image--highlight' : ''}`} />
            </div>
        );
    }

    if(videoEmbedSnippet) {
        return (
            <div className="w-full mt-4 mb-4" dangerouslySetInnerHTML={{ __html: videoEmbedSnippet }}></div>
        );
    }

    return null;

};

const DateDisplay = ({dateString, style}:{dateString:string, style:BlogEntryCardStyle}) => {

    if(style === BlogEntryCardStyle.Normal)
        return <div className="h-5" />;

    const highlight = style === BlogEntryCardStyle.Highlight;
    const detail = style === BlogEntryCardStyle.Detail;
    const date = new Date(dateString);
    const format = new Intl.DateTimeFormat([], { month: 'long', day: 'numeric', year: 'numeric' });
    const parts = format.formatToParts(date);
    const monthPart = parts.filter(({type}) => type === 'month')[0].value;
    const datePart = parts.filter(({type}) => type === 'day')[0].value;
    const yearPart = parts.filter(({type}) => type === 'year')[0].value;
    const formattedDate = `${monthPart} ${datePart}, ${yearPart}`;

    return (
        <div className="md:px-0">
            <div className={
                    `${
                        highlight?
                            'md:rounded-tr-lg bg-white md:pt-14' :
                            ''
                        } ${detail? 'pt-2.5 md:mt-4' : 'pt-6'
                    }`
                }
            >
                <p className={`text-sm ${highlight? 'md:text-base-sm' : ''} opacity-50`}>
                    {formattedDate}
                </p>
            </div>
        </div>
    )
};

const Title = ({title, style}:{title:any[], style:BlogEntryCardStyle}) => {
    const highlight = style === BlogEntryCardStyle.Highlight;
    const detail = style === BlogEntryCardStyle.Detail;
    return (
        <div className="md:px-0">
            <div className={`pt-2 ${highlight? 'bg-white' : ''}`}>
                {
                    textItemsFromField(
                        title,
                        (text, index) => (
                            <h1
                                className={
                                    `${
                                        detail? 'text-3xl md:text-5xl-3 md:leading-tight' :
                                        (
                                            highlight?
                                                'text-2xl-2 md:text-3xl' :
                                                'text-2xl md:tracking-normal md:leading-normal'
                                        )
                                    } tracking-tight`
                                }
                                key={`item-${index}`}
                            >
                                {text}
                            </h1>
                        )
                    )
                }
            </div>
        </div>
    );
}

const Body = ({body, style, mode}:{body:any, style:BlogEntryCardStyle, mode:"header-only" | "body-only"}) => {
    const normal = style === BlogEntryCardStyle.Normal;
    const highlight = style === BlogEntryCardStyle.Highlight;
    const detail = style === BlogEntryCardStyle.Detail;
    const firstParaRendered = useRef(false);
    firstParaRendered.current = false;
    return (
        <div className="md:px-0">
            <div
                className={
                    `${
                        highlight?
                        'rounded-br-lg bg-white md:pt-2.5 pb-8 md:pb-12' :
                        ''
                    } ${detail?
                        'pt-2 md:pt-4 blog-entry-content' :
                        'pt-1'
                    } blog__body ${highlight? 'blog__body--highlight' : ''}`
                }
            >
                {
                    <PrismicRichText field={body} components={{
                        heading1: ({children, key}) =>
                        (
                            (mode === "header-only" && !firstParaRendered.current) ||
                            (mode === "body-only" && firstParaRendered.current)
                        )?
                            <h1 className="mt-8 text-2xl font-bold" key={key}>{children}</h1> :
                            <div key={key} />,
                        heading2: ({children, key}) =>
                        (
                            (mode === "header-only" && !firstParaRendered.current) ||
                            (mode === "body-only" && firstParaRendered.current)
                        )?
                            <h2 className="font-bold" key={key}>{children}</h2> :
                            <div key={key} />,
                        paragraph: ({children, key}) => {
                            let output = <div key={key} />;
                            if(
                                (mode === "header-only" && !firstParaRendered.current) ||
                                (mode === "body-only" && firstParaRendered.current)
                            ) {
                                output = (
                                    <p
                                        className={
                                            `md:max-w-4xl blog__para ${
                                                normal? 'pt-1 text-sm' : ''
                                            } ${
                                                (normal || highlight)? 'blog__para--non-detail' : ''
                                            } ${
                                                detail? 'pt-6' : ''
                                            }`
                                        }
                                        key={key}>{children}
                                    </p>
                                );
                            }
                            firstParaRendered.current = true;
                            return output;
                        }
                    }} />
                }
                
            </div>
        </div>
    );
};

export default function BlogEntryCard({
    mainImageURL,
    videoEmbedSnippet,
    date,
    title,
    body,
    intro,
    related_link_label,
    related_link_url,
    style,
    themeColour
}:Props) {

    style = style || BlogEntryCardStyle.Normal;

    const highlight = style === BlogEntryCardStyle.Highlight;

    return (
        <div className={'mt-8 filter drop-shadow-standard pb-8 text-gcc-dark blog__card'}>
            {
                style === BlogEntryCardStyle.Detail? (
                    <div className="blog__card-contents blog__card-contents--detail">
                        <div className="blog__text">
                            <Title title={title} style={style} />
                            <DateDisplay dateString={date} style={style} />
                            <Body body={intro} style={style} mode="header-only" />
                            <MainImage mainImageURL={mainImageURL} videoEmbedSnippet={videoEmbedSnippet} style={style} themeColour={themeColour} />
                            <Body body={body} style={style} mode="body-only" />
                        </div>
                    </div>
                ) : (
                    <div className={`blog__card-contents ${highlight? 'md:flex blog__card-contents--highlight' : ''}`}>
                        <MainImage mainImageURL={mainImageURL} videoEmbedSnippet={videoEmbedSnippet} style={style} themeColour={themeColour} />
                        <div className={`blog__text ${highlight? "md:w-3/5 blog__text--highlight" : ""}`}>
                            <DateDisplay dateString={date} style={style} />
                            <Title title={title} style={style} />
                            <Body body={intro} style={style} mode="header-only" />
                        </div>
                    </div>
                )
            }
            {
                related_link_label &&
                <div className="blog__card-contents--detail px-5 md:px-0 mt-8 md:mt-12 md:w-80 h-14 md:h-12 md:text-base-sm">
                    <StandardLinkButton document={related_link_url}>
                        {related_link_label}
                    </StandardLinkButton>
                </div>
            }
        </div>
    );
}