import React from "react"
import PrismicCTAButton from "../PrismicCTAButton"

const CareersSlice = (props:any) => {
  const {data} = props;
    const primary = data? data.primary : null;
    return (
        primary && (
            <div className={ `careers bg-white pt-9 md:pt-14 px-10 pb-10 md:pb-14 text-center ${primary.gray_background ? 'bg-gcc-medium-light-gray-2' : ''}` }>
                <div>
                {primary.c05_career_title.map((title:any, index:number) => {
                    return (
                        <h1 className="text-3xl md:text-4xl-2" key={index}>{title.text}</h1>
                    )
                })}
                </div>
                {primary.c05_career_content.map((content:any, index:number) => {
                    return (
                        <p className="mt-2 md:mt-4 md:mx-auto md:w-135 md:leading-relaxed" key={index}>
                            {content.text}
                        </p>
                    )
                })}
                <PrismicCTAButton document={primary.c05_view_more} label={'View current openings'} centre></PrismicCTAButton>
            </div>
         )
    )
  }

export default CareersSlice;