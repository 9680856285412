import React from "react";
import PrismicCTAButton from "../PrismicCTAButton";

export default function CallToActionSlice(props:any) {

    const data = props.data;
    const primary = data? data.primary : null;

    if(primary) {
        const {
            message,
            supporting_text,
            centralise_text,
            restrict_text_width,
            button_label,
            button_link,
            button_link_external,
            background_image
        } = primary;

        return (
            <div
                className={
                    `pt-12 md:pt-16 px-7 pb-8 md:pb-16 ${
                        centralise_text? 'text-center' : ''
                    } ${
                        background_image.url? ' text-white' : ''
                    } bg-cover`
                }
                style={background_image && {backgroundImage: `url(${background_image.url})`}}
            >
                <h1 className="md:mx-auto text-2xl-2 md:text-4xl-2 leading-8 md:leading-snug">{message}</h1>
                <p className={`mt-2 mx-auto ${restrict_text_width? 'w-60 md:w-170 ': ''} md:text-lg`}>
                    {supporting_text}
                </p>
                {
                    button_label &&
                    <PrismicCTAButton
                        document={button_link}
                        label={button_label}
                        centre
                        linkExternal={button_link_external}
                    ></PrismicCTAButton>
                }
            </div>
        );
    }

    return null;
}