import React, { useCallback, useLayoutEffect, useState } from "react";
import { textItemsFromField } from "../../PrismicUtils";

const CarouselSlice = (props:any) => {

    const {data} = props;
    const items = data && data.items;
    const primary = data && data.primary;
    const [measureNode, setMeasureNode] = useState<HTMLDivElement | null>(null);
    const measuredRef = useCallback((node) => setMeasureNode(node), []);
    const [cardWidth, setCardWidth] = useState(0);
    const [cardIndex, setCardIndex] = useState(0);

    useLayoutEffect(() => {
        if(measureNode)
            setCardWidth(measureNode.getBoundingClientRect().width);
    }, [measureNode])

    if(primary && items) {

        const {title} = primary;

        return (
            <div className='carousel md:mt-16 pb-20'>
                <div className="carousel__first-pane">
                    <div className="carousel__text">
                        {
                            textItemsFromField(title, (text, index) =>
                                <h1
                                    className="md:mx-auto pl-7 pr-11 text-2xl-2 md:text-2xl-3 md:text-center md:tracking-tight leading-8"
                                    style={{maxWidth: '890px'}}
                                    key={`item-${index}`}
                                >
                                    {text}
                                </h1>
                            )
                        }
                    </div>
                    <img className="carousel__supporting-image" src={primary.supporting_image.url} alt={primary.supporting_image.alt} />
                </div>
                <div
                    className='scroll-container mt-9 mx-4 overflow-x-scroll md:overflow-x-visible'
                    onScroll={(e) => setCardIndex(Math.floor((e.target as HTMLDivElement).scrollLeft / cardWidth))}
                >
                    <div className='scroll-content flex md:flex-wrap md:justify-center'>
                        {items && items.map(({c02_slide_title, c02_slide_content, image}:any, index:number) => {
                            return (
                                <div
                                    className=
                                        'item ml-2 md:ml-6 md:mb-6 w-screen-85 md:h-48 rounded-xl relative bg-gcc-medium-light-gray-2 pt-6 pl-5 md:pl-6 pr-3 pb-3 md:flex'
                                    key={index}
                                    ref={index === 0? measuredRef : null}
                                >
                                    {image && <img className="hidden md:block w-36 h-36" src={image.url} alt={image.alt} />}
                                    <div className="md:pl-5">
                                        {
                                            textItemsFromField(c02_slide_title, (text, index) =>
                                                <h1
                                                    className="text-xl md:text-2xl text-gcc-bright-orange"
                                                    key={index}
                                                >
                                                    {text}
                                                </h1>
                                            )
                                        }
                                        {
                                            textItemsFromField(c02_slide_content, (text, index) =>
                                                <p className="mt-2 md:leading-relaxed" key={index}>{text}</p>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="md:hidden mt-6 flex justify-center">
                    {
                        items.map((item:any, index:number) =>
                            <div
                                className={
                                    `ml-3 first:ml-0 w-2.5 h-2.5 rounded-2xl ${
                                        index === cardIndex? '' : 'opacity-25'
                                    } bg-gcc-dark`
                                }
                                key={`item-${index}`} />
                            )
                    }
                </div>
            </div>
        );
    }
    return null;
  }
export default CarouselSlice;