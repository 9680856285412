import { usePrismicClient, usePrismicDocumentByUID } from "@prismicio/react";
import Prismic from "prismic-javascript";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import BlogEntryCard, { BlogEntryCardStyle } from "../BlogEntryCard";
import { useParams } from "react-router-dom";

const NUM_SUGGESTED_ARTICLES = 3;
const ARTICLE_INC = 4;

export default function BlogSlice() {

    const client = usePrismicClient();
    const [articles, setArticles] = useState<any[]>();
    const [pageSize, setPageSize] = useState(13);
    const [numArticles, setNumArticles] = useState(0);
    const {uid} = useParams<{uid:string}>();
    const [focussedDocument] = usePrismicDocumentByUID('gcc_blog_entry', uid);
    const focussedArticle:any = useMemo(() => focussedDocument && focussedDocument.data, [focussedDocument]);

    useEffect(() => {
        (async () => {
            const config = {
                orderings: '[my.gcc_blog_entry.date desc]',
                pageSize: focussedArticle? NUM_SUGGESTED_ARTICLES : pageSize,
            };
            let preds = [Prismic.Predicates.at('document.type', 'gcc_blog_entry')];
            const response = await client.query(preds, config);
            const results:any[] = response.results;
            setArticles(results);
            setNumArticles(response.total_results_size);
        })();
    }, [client, pageSize, focussedArticle]);

    return (

        <div
            className={
                `bg-gcc-medium-light-gray-2 pb-10 min-h-screen pt-20 md:pt-28 ${
                    focussedArticle? '' : 'md:px-1/20'
                }`
            }
        >
            {
                focussedArticle?
                    <Fragment>
                        <a className="px-5 md:px-5 flex md:flex items-center text-sm " href='/blog'>
                            <img
                                className="w-2.5"
                                style={{transform: 'scaleX(-1)'}}
                                src='/images/arrow-right-orange.svg'
                                alt='Back'
                            />
                            <span className="ml-2.5 text-gcc-bright-orange">Back to blog</span>
                        </a>
                        <BlogEntryCard
                            mainImageURL={focussedArticle.main_image && focussedArticle.main_image.url}
                            videoEmbedSnippet={focussedArticle.video_embed_snippet}
                            title={focussedArticle.title}
                            intro={focussedArticle.intro}
                            date={focussedArticle.date}
                            body={focussedArticle.body}
                            related_link_label={focussedArticle.related_link_label}
                            related_link_url={focussedArticle.related_link_url}
                            themeColour={focussedArticle.theme_colour}
                            style={BlogEntryCardStyle.Detail}
                        />
                        <a className="px-5 md:px-0 flex md:flex justify-center text-sm " href='/blog'>
                            <img
                                className="w-2.5"
                                style={{transform: 'scaleX(-1)'}}
                                src='/images/arrow-right-orange.svg'
                                alt='Back'
                            />
                            <span className="ml-2.5 text-gcc-bright-orange">Back to blog</span>
                        </a>
                    </Fragment> :
                    null
            }
            <div className={`blog__article-wrapper ${focussedArticle? 'blog__article-wrapper--detail' : ''}`}>
                { focussedArticle && articles? (
                    <div className="font-bold text-base-lg text-center text-gcc-bright-orange uppercase">
                        Read more from Life at GCC
                    </div>
                ) : null}
                <div className='md:flex md:flex-wrap mx-auto blog__article-container'>
                    {
                        articles &&
                        articles.map(({uid: entryUID, data}, articleIndex) => (
                            (() => {

                                if(entryUID === uid)
                                    return null;

                                const cardStyle =
                                    (!focussedArticle && articleIndex === 0)?
                                        BlogEntryCardStyle.Highlight :
                                        BlogEntryCardStyle.Normal;
                                
                                return (
                                    <Fragment key={`item-${articleIndex}`}>
                                        <a
                                            className={`block ${cardStyle === BlogEntryCardStyle.Normal? 'blog__article-item' : ''}`}
                                            href={`/blog/${entryUID}`}
                                        >
                                            <BlogEntryCard
                                                mainImageURL={data.main_image && data.main_image.url}
                                                videoEmbedSnippet={null}
                                                title={data.title}
                                                intro={data.intro}
                                                body={data.body}
                                                date={data.date}
                                                themeColour={data.theme_colour}
                                                style={cardStyle}
                                            />
                                        </a>
                                    </Fragment>
                                );
                            
                            })()
                        ))
                    }
                </div>
            </div>
            <div className="mt-6 md:mt-0 mb-8 text-center font-bold text-base-sm text-gcc-bright-orange underline">
            {
                focussedArticle?
                    null :
                    (
                        pageSize < numArticles?
                            <p
                                onClick={() => setPageSize(Math.min(pageSize + ARTICLE_INC, numArticles))}
                            >
                                Load more
                            </p> :
                            null
                    )
            }
            </div>
        </div>
    );
}