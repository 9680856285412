import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'

import { PrismicProvider } from '@prismicio/react'
import { client, apiEndpoint, linkResolver } from './prismic-configuration'
import { Preview, NotFound } from './pages'
import Home from './pages/Home'
import './main.scss';
import Innovation from './pages/Innovation'
import Jobs from './pages/Jobs'
import Blog from './pages/Blog'
import People from './pages/People'
import Terms from './pages/Terms'
import Culture from './pages/Culture'
import AboutUs from './pages/AboutUs'

/**
 * Main application componenet
 */
const App = (props) => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint)
  const repoName = repoNameArray[1]

  return (
    <PrismicProvider client={client} linkResolver={linkResolver}>
      <Fragment>
        <Helmet>
          <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
        </Helmet>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/innovation' component={Innovation} />
            <Route exact path='/jobs' component={Jobs} />
            <Route exact path='/preview' component={Preview} />
            <Route exact path='/blog' component={Blog} />
            <Route exact path='/blog/:uid' component={Blog} />
            <Route exact path='/people' component={People} />
            <Route exact path='/terms-and-conditions' component={Terms} />
            <Route exact path='/culture' component={Culture} />
            <Route exact path='/about-us' component={AboutUs} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Fragment>
    </PrismicProvider>
  )
}

export default App
