import React from 'react';
import { usePrismicDocumentByUID } from '@prismicio/react'
import PageContent from '../components/PageContent';

const Blog = () => {
    const [document] = usePrismicDocumentByUID('gcc_content_page', 'blog')
    return <PageContent document={document} sectionClass='blog' />
}

export default Blog;
