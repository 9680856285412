import { useSinglePrismicDocument } from "@prismicio/react";
import React from "react";
import {Route} from "react-router-dom";
import MenuButton from "./MenuButton";
import StandardLinkButton from "./StandardLinkButton";

type Props = {
    menuItems:{label:string, url:string}[];
    onDismiss:() => void;
}

const MobileMenu = ({menuItems, onDismiss}:Props) => {
    
    const [document] = useSinglePrismicDocument('gcc_global_header');

    return (
        
        <div className="bg-gcc-light-gray h-screen">
            <div className="w-screen pt-5 pr-4 flex justify-end">
                <MenuButton highlight={true} onClick={onDismiss} />
            </div>
            {
                document && document.data && document.data.logo &&
                <Route render={({ history }) => (
                    <img
                        className="mt-8 mx-auto w-1/2"
                        alt="GCC Logo"
                        src="/images/gcc-logo-trans@3x.png"
                        onClick={() => { history.push('/') }}
                    />
                )} />
            }
            <ul className="mt-20 pl-6 text-lg font-bold">
                {
                    menuItems.map(
                        ({label, url}, index) =>
                            <li
                                className="last:mb-0 mb-7 h-8 border-b border-solid border-gcc-gray/25 pb-9"
                                key={`item-${index}`}
                            >
                                <a className="text-gcc-dark" href={url}>{label}</a>
                            </li>
                    )
                }
            </ul>
            <div className="mx-auto mt-20 w-64 h-11">
                    <StandardLinkButton href="/jobs">Join our team</StandardLinkButton>
            </div>
        </div>
    );
};

export default MobileMenu;