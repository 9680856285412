import React from "react";

const ImageCollageSlice = ({data}:any) => {
  return (
    <div>
      {data.items.map(({image}:any, index:number) => (
        <div style={{backgroundImage: `url('${image.url}')`}} key={`image-${index}`} />
      ))}
    </div>
  )
};

export default ImageCollageSlice;