import React from "react";
import { textItemsFromField } from "../../PrismicUtils";

const SectionHeaderSlice = (props:any) => {

    const data = props.data;
    const primary = data? data.primary : null;

    if(primary) {

        const {
            section_header_title,
            section_header_subtitle,
            section_header_body,
            white_background,
            mobile_background_image,
            desktop_background_image
        } = primary;

        const mobileBGImageURL = mobile_background_image.url;
        const desktopBGImageURL = desktop_background_image.url;

        return (
            <div className="relative bg-center bg-cover" style={{ minHeight: `${desktopBGImageURL || mobileBGImageURL ? '500px' : 'auto'}`}}>
                <div className="md:hidden bg-center bg-cover absolute top-0 left-0 w-full h-full" style={{ backgroundImage: `url(${mobileBGImageURL ? mobileBGImageURL : ''})`}}></div>
                <div className="hidden md:block bg-center bg-cover absolute top-0 left-0 w-full h-full" style={{ backgroundImage: `url(${desktopBGImageURL ? desktopBGImageURL : ''})`}}></div>
                <div className={
                    `${mobileBGImageURL? 'absolute top-0 left-0 w-full h-full flex flex-col justify-center' : (white_background ? 'bg-white' : 'bg-gcc-medium-light-gray-2') + ' pt-24 md:pt-36 pb-16 md:pb-20'} ${white_background ? 'bg-white' : ''} px-5 md:text-base-lg text-center`
                }
                >
                    {
                        textItemsFromField(
                            section_header_title, (text, index) =>
                                <h1
                                    className=
                                        "mx-auto w-auto md:w-auto font-bold text-3xl md:text-5xl-2 tracking-tight md:leading-tight"
                                    style={{maxWidth: '890px'}}
                                    key={`item-${index}`}
                                >
                                    {text}
                                </h1>
                            )
                    }
                    {
                        textItemsFromField(
                            section_header_subtitle,
                            (text, index) =>
                                <h2 className="mt-2 md:mt-6 font-bold" key={`item-${index}`}>{text}</h2>
                        )
                    }
                    {
                        textItemsFromField(
                            section_header_body,
                            (text, index) =>
                                <p className="mt-2 md:mt-4 mx-auto md:w-170" key={`item-${index}`}>{text}</p>
                        )
                    }
                </div>
            </div>
        );
    }

    return null;
};

export default SectionHeaderSlice;