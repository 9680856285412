import React from "react";
import { PrismicLink } from "@prismicio/react";

type Props = {
    label: any;
    document: any;
    centre?:boolean;
    linkExternal?:boolean;
}

const PrismicCTAButton = ({label, document, centre, linkExternal}:Props) => (
    <div className={`mt-8 ${centre? 'mx-auto' : ''} md:w-72 h-14 md:h-12 text-lg md:text-base-sm`}>
        <PrismicLink
            className={`w-full h-full bg-gcc-bright-orange hover:bg-gcc-hover rounded-full text-center font-bold text-white flex items-center justify-center`}
            field={document}
            externalComponent={
                linkExternal?
                    (props:any) => <a {...props} target='_blank' rel='noopener noreferrer' /> :
                    undefined
            }
        >
            {label}
        </PrismicLink>
    </div>
);

export default PrismicCTAButton;