import React from 'react';
import { usePrismicDocumentByUID } from '@prismicio/react'
import PageContent from '../components/PageContent';

const Home = () => {
  const [document] = usePrismicDocumentByUID('gcc_content_page', 'home-page')
  return <PageContent document={document} sectionClass='home' />
}

export default Home;
