import React from "react";

type Props = {
    highlight:boolean;
    onClick:() => void;
}

const BurgerBar =
    ({highlight}:{highlight:boolean}) =>
        <div className={`w-full h-1 bg-gcc-dark-gray ${highlight? ' bg-gcc-bright-orange' : ''}`} />;

const MenuButton = ({highlight, onClick}:Props) => (
    <button
        className="w-7 h-6 flex flex-col justify-between"
        onClick={onClick}
    >
        <BurgerBar highlight={highlight} />
        <BurgerBar highlight={highlight} />
        <BurgerBar highlight={highlight} />
    </button>
);

export default MenuButton;