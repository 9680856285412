import React, { FunctionComponent } from "react"

// Components
import CareersSlice from "./slices/CareersSlice";
import HeroSlice from "./slices/HeroSlice";
import CarouselSlice from "./slices/CarouselSlice";
import FullWidthImageSlice from "./slices/FullWidthImageSlice";
import SectionHeaderSlice from "./slices/SectionHeaderSlice";
import FeaturesSlice from "./slices/FeaturesSlice";
import TestimonialFeatureSetSlice from "./slices/TestimonialFeatureSetSlice";
import JobListSlice from "./slices/JobListSlice";
import BenefitsSlice from "./slices/BenefitsSlice";
import CallToActionSlice from "./slices/CallToActionSlice";
import BlogSlice from "./slices/BlogSlice";
import PeopleSlice from "./slices/PeopleSlice";
import ParagraphSlice from "./slices/ParagraphSlice";
import RichTextSlice from "./slices/RichTextSlice";
import TextLozengeSlice from "./slices/TextLozengeSlice";
import TextBannerSlice from "./slices/TextBannerSlice";
import ImageSlice from "./slices/ImageSlice";
import InfographicSlice from "./slices/InfographicSlice";
import VideoCarouselSlice from "./slices/VideoCarouselSlice";
import ImageCollageSlice from "./slices/ImageCollageSlice";
import ValuesSlice from "./slices/ValuesSlice";

const sliceTypeComponentMap:{[id:string]:FunctionComponent} = {
    hero_component: HeroSlice,
    carousel_slide: CarouselSlice,
    feature_rows: FeaturesSlice,
    career_component: CareersSlice,
    full_width_image: FullWidthImageSlice,
    // testimonials: TestimonialsSlice,
    section_header: SectionHeaderSlice,
    testimonial_feature: TestimonialFeatureSetSlice,
    job_list: JobListSlice,
    benefit_component: BenefitsSlice,
    call_to_action_component: CallToActionSlice,
    blog_slice: BlogSlice,
    people_component: PeopleSlice,
    paragraph_component: ParagraphSlice,
    rich_text_component: RichTextSlice,
    text_lozenge_component: TextLozengeSlice,
    text_banner_component: TextBannerSlice,
    image_component: ImageSlice,
    infographic_component: InfographicSlice,
    video_carousel: VideoCarouselSlice,
    image_collage: ImageCollageSlice,
    values_component: ValuesSlice,
};

type SliceComponentProps = {
    sliceType:string;
    data:any;
};

const SliceComponent = ({sliceType, data}:SliceComponentProps) => {
    const Component = sliceTypeComponentMap[sliceType];
    if(Component !== undefined) {
        const compProps = {key: sliceType, data};
        return compProps && <Component {...compProps} />;
    }
    return null;
};

const SlicesBlock =
    (props:any) => props.slice && <SliceComponent sliceType={props.slice.slice_type} data={props.slice} />;

export default SlicesBlock;