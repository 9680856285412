import React from "react";

export default function TextLozengeSlice(props:any) {

    const content = props && props.data && props.data.primary && props.data.primary.content;

    return content &&
        <div className="my-12 px-3">
            <p
                className=
                    "md:mx-24 rounded-2xl bg-gcc-orange pt-8 md:pt-9 px-4 md:px-24 pb-7 md:pb-10 font-bold text-xl text-white text-center"
            >
                {content}
            </p>
        </div>;
}