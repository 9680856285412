import { PrismicRichText } from "@prismicio/react";
import React, { useEffect, useState } from "react";

type ValueItemProps = {
  title:string;
  detail:string;
  itemNo:number;
  imageURL:string;
  actionLabel:string;
  onSelect:() => void;
  onClose:() => void;
  isSelected:boolean;
}

const ValueItem = ({title, detail, itemNo, imageURL, actionLabel, onSelect, onClose, isSelected}:ValueItemProps) => {

  const [shouldShowDetail, setShouldShowDetail] = useState(false);

  useEffect(() => {
    if(!isSelected)
      setShouldShowDetail(false);
  }, [isSelected]);

  return (
    <div
      className="values-component__value-item-wrapper"
      tabIndex={0}
      onClick={() => isSelected? onClose() : onSelect()}
    >
      <div
        className={`values-component__value-item ${isSelected? 'values-component__value-item--selected' : ''}`}
        style={{backgroundImage: `url('${imageURL}')`}}
        onTransitionEnd={() => isSelected && setShouldShowDetail(true)}
      >
        <div className="values-component__value-item-number font-bold">{itemNo}</div>
        <div
          className={
            `values-component__value-item-spacer ${isSelected? 'values-component__value-item-spacer--collapse' : ''}`
          }
        />
        <div className="values-component__value-item-title font-bold">{title}</div>
        <div
          className={
            `values-component__value-item-detail ${shouldShowDetail? 'values-component__value-item-detail--show' : ''}`
          }
        >
          {detail}
        </div>
        <div className="values-component__value-item-more-prompt">{actionLabel}</div>
        <div className="values-component__value-item-shade" />
      </div>
    </div>
  );

};

export default function ValuesSlice({data}:any) {
  const {primary, items} = data;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  return (
    <div className="values-component">
      <div className="values-component__heading font-bold">
        <PrismicRichText field={primary.heading} />
      </div>
      <div className="values-component__intro">
        <PrismicRichText field={primary.intro} />
      </div>
      <div className="values-component__select-prompt">{primary.select_prompt}</div>
      <div className="values-component__value-scroll-container">
        <div className="values-component__value-container">
          {
            items.map(({title, detail, image}:any, index:number) => {
                const isSelected = index === selectedIndex;
                return (
                  <ValueItem
                    title={title}
                    detail={detail}
                    imageURL={image.url}
                    itemNo={index + 1}
                    actionLabel={isSelected? primary.close_prompt : primary.more_prompt}
                    key={`item=${index}`}
                    onSelect={() => setSelectedIndex(index)}
                    onClose={() => setSelectedIndex(-1)}
                    isSelected={isSelected}
                  />
                );
            })
          }
        </div>
      </div>
    </div>
  );
}