import { PrismicRichText } from "@prismicio/react";
import React from "react";

export default function RichTextSlice({data}:any) {
    const content = data && data.primary && data.primary.content;
    return content && (
        <div className="py-8 md:pt-14 px-5 md:px-25 md:flex md:flex-col md:items-center md:text-center">
            <PrismicRichText
                field={content}
                components={{
                    heading1: ({children, key}) =>
                        <h1 className="text-2xl-2 md:text-4xl-2 tracking-tight" key={key}>{children}</h1>,
                    heading2: ({children, key}) =>
                        <h2
                            className="font-bold text-xl2 md:text-2xl-3 leading-7 md:leading-tight md:text-left"
                            key={key}
                        >
                            {children}
                        </h2>,
                    paragraph: ({children, key}) =>
                        <p className="mt-2.5 leading-7" style={{maxWidth: '664px'}} key={key}>{children}</p>,
                }}
            />
        </div>
    );
}