import React from 'react';
import { usePrismicDocumentByUID } from '@prismicio/react'
import PageContent from '../components/PageContent';

const Jobs = () => {
    const [document] = usePrismicDocumentByUID('gcc_content_page', 'jobs')
    return <PageContent document={document} sectionClass='jobs' />
}

export default Jobs;
