import React, { useState } from "react";
import MenuBar from "./MenuBar";
import MenuButton from "./MenuButton";
import MobileMenu from "./MobileMenu";

const menuItems = [
    {label: "Innovation", url: "/innovation"},
    {label: "Culture", url: "/culture"},
    {label: "People", url: "/people"},
    {label: "About us", url: "/about-us"},
    {label: "Life at GCC", url: "/blog"},
];

const Menu = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    return (
        <div className="md:w-full">
            <div className="md:hidden">
                <MenuButton highlight={false} onClick={() => setShowMobileMenu(true)} />
            </div>
            <div className="hidden md:block">
                <MenuBar menuItems={menuItems} />
            </div>
            {
                showMobileMenu?
                    <div className="fixed top-0 left-0 w-full  z-50 border-gcc-bright-orange border-solid border-t-4">
                        <MobileMenu menuItems={menuItems} onDismiss={() => setShowMobileMenu(false)} />
                    </div> :
                    null
            }
        </div>
    );
}

export default Menu;