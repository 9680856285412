import React from "react";
import FeatureRowsSlice from "./FeatureRowsSlice";

const FeaturesSlice = (props:any) => {
    const {data} = props;
    const items = data? data.items : null;
    const {primary} = data;

    if(primary) {
        const {use_dark_background, start_text_left, mobile_swap_pane_order} = primary;
        return (
            <div className={`${use_dark_background? 'bg-gcc-medium-light-gray' : ''}`}>
                {
                    items &&
                    items.map((item:any, index:number) =>
                        <FeatureRowsSlice
                            data={item}
                            index={index}
                            startTextLeft={start_text_left}
                            mobileSwapPaneOrder={mobile_swap_pane_order}
                            key={index}
                        />
                    )
                }
            </div>
        );
    }

    return null;
};

export default FeaturesSlice;