import { PrismicRichText } from "@prismicio/react";
import React, { Fragment, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { textItemsFromField } from "../../PrismicUtils";

const NUM_COLS_NON_MD = 2;
const NUM_COLS_MD = 4;

function chunkify(array:any[], chunkSize:number):any[][] {
    
    const numChunks = Math.floor((array.length + chunkSize - 1) / chunkSize);
    const output:any[][] = [];

    for(let i = 0; i < numChunks; i ++) {
        const start = i * chunkSize;
        output.push(array.slice(start, start + chunkSize));
    }

    return output;

}

const DetailCard = ({item, onCloseAction}:{item:any, onCloseAction:() => void}) => {

    const {team_member_image, team_member_name, team_member_role, team_member_detail} = item;

    return (
        <div className="relative w-full rounded-xl mt-4 mb-10 bg-white md:flex">
            <header className="pt-3 px-3 md:pb-3">
                <div
                    className="w-full md:w-54 h-screen-45 md:h-60 rounded-xl bg-cover bg-center"
                    style={{backgroundImage: `url(${team_member_image && team_member_image.url})`}}
                >
                </div>
            </header>
            <main className="pt-4 md:pt-7 px-4 md:pl-14 md:pr-12 pb-5 md:pb-10">
                <div className="font-bold text-lg text-gcc-bright-orange">
                    <p className="md:leading-5">{team_member_name}</p>
                    <p>{team_member_role}</p>
                </div>
                <PrismicRichText
                    field={team_member_detail}
                    components={{
                        paragraph: ({children}) => <p className="mt-4 leading-7">{children}</p>
                    }}
                />
            </main>
            <img
                className="absolute right-5 top-5 filter md:filter-none brightness-200 cursor-pointer"
                src='/images/close.svg'
                alt='Close'
                onClick={onCloseAction}
            />
        </div>
    );
};

export default function PeopleSlice(props:any) {
    
    const {data} = props;
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const isMD = useMediaQuery({query: '(min-width: 992px)'});
    const numCols = useMemo(() => isMD? NUM_COLS_MD : NUM_COLS_NON_MD, [isMD]);
    const detailRowIndex = useMemo(() => Math.floor(selectedIndex / numCols), [selectedIndex, numCols]);

    if(data) {

        const {primary, items} = data;

        if(primary) {
            const {title, intro} = primary;
            return (
                <div
                    className="bg-gradient-to-b from-white to-gcc-light-gray py-8 md:pt-14 px-5 md:px-0 md:pb-19 md:flex md:flex-col md:items-center"
                >
                    <div className="md:text-center">
                        { textItemsFromField(title, (text, index) =>
                            <h1 className="text-3xl md:text-4xl-2" key={`item-${index}`}>{text}</h1>
                        ) }
                        <p className="mt-2 md:mt-5 md:leading-7" style={{maxWidth: '786px'}}>{ intro }</p>
                    </div>
                    {
                        items &&
                        <div className="mt-10 md:mt-14 w-full md:px-screen-5">
                            {
                                chunkify(items, numCols).map((itemChunk, rowIndex) => (
                                    <Fragment key={`row-${rowIndex}`}>
                                        <div className="w-full flex justify-between md:justify-center">
                                            {
                                                itemChunk.map((
                                                    {team_member_image, team_member_name, team_member_role},
                                                    colIndex
                                                ) => {
                                                    const memberIndex = rowIndex * numCols + colIndex;
                                                    return (
                                                        <div
                                                            className='cursor-pointer ml-screen-2 first:ml-0 mb-8 w-1/2 md:w-54 h-screen-60 md:h-80 text-gcc-dark text-center'
                                                            onClick={() => setSelectedIndex(memberIndex)}
                                                            key={`col-${colIndex}`}
                                                        >
                                                            {
                                                                team_member_image &&
                                                                <img
                                                                    className={`rounded-md border-2 hover:border-gcc-bright-orange ${
                                                                        memberIndex === selectedIndex?
                                                                            'border-2 border-gcc-bright-orange' :
                                                                            ''
                                                                    }`}
                                                                    src={team_member_image.url} alt={ team_member_name + ", " + team_member_role } />
                                                            }
                                                            <p className="mt-1 md:mt-2.5 font-semibold">{ team_member_name }</p>
                                                            <p className="md:mt-1 opacity-60 text-base-sm leading-tight">
                                                                { team_member_role }
                                                            </p>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        {
                                            selectedIndex !== -1 && rowIndex === detailRowIndex?
                                                <DetailCard
                                                    item={items[selectedIndex]}
                                                    onCloseAction={() => setSelectedIndex(-1)}
                                                /> :
                                                null
                                        }
                                    </Fragment>
                                ))
                                // items.map(({
                                //     team_member_image,
                                //     team_member_name,
                                //     team_member_role,
                                // }:any, index:number) => (
                                //     <Fragment key={`item-${index}`}>
                                //         <div
                                //             className= 'mb-4 w-screen-42 md:w-54 h-screen-60 md:h-80 text-gcc-dark text-center'
                                //             onClick={() => setSelectedIndex(index)}
                                //         >
                                //             {
                                //                 team_member_image &&
                                //                 <img
                                //                     className={`rounded-md ${
                                //                         index === selectedIndex?
                                //                             'border-2 border-gcc-bright-orange' :
                                //                             ''
                                //                     }`}
                                //                     src={team_member_image.url} alt='' />
                                //             }
                                //             <p className="mt-1 md:mt-2.5 font-semibold">{ team_member_name }</p>
                                //             <p className="md:mt-1 opacity-60 text-base-sm leading-tight">
                                //                 { team_member_role }
                                //             </p>
                                //         </div>
                                //         {
                                //             selectedIndex !== -1 && index === detailIndex?
                                //                 <DetailCard
                                //                     item={items[selectedIndex]}
                                //                     onCloseAction={() => setSelectedIndex(-1)}
                                //                 /> :
                                //                 null
                                //         }
                                //     </Fragment>
                                // ))
                            }
                        </div>
                    }
                </div>
            );
        }

    }

    return null;
}