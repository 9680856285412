import React from "react";
import { textItemsFromField } from "../../PrismicUtils";

export default function InfographicSlice({data}:any) {

    if(data) {

        const {primary} = data;
        const {items} = data;

        if(primary) {

            const {title} = primary;

            return (
                <div className="mb-10 px-6 md:border md:mx-24 md:border-gcc-orange md:rounded-2xl md:pt-8 md:flex md:flex-col md:items-center md:text-center">

                    <div className="text-xl2 tracking-tight leading-7 md:text-2xl-3 md:tracking-normal md:leading-9">
                        { textItemsFromField(title, (text, index) =>
                            <h1 className="mb-6" style={{maxWidth: '760px'}} key={`item-${index}`}>{ text }</h1>
                        )}
                    </div>
                    {
                        items &&
                        <div className="md:pb-6 md:flex">
                            {
                                items.map(({information, infographic}:any, index:number) => (
                                    <div className={` ${(index < items.length - 1)? 'md:border-gcc-orange md:border-r' : ''}`} key={`item-${index}`}>
                                        <p
                                            className={`border-t border-gcc-orange pt-6 md:pt-0 md:border-t-0 md:px-8 md:leading-7`}
                                        >
                                            { information }
                                        </p>
                                        {
                                            infographic &&
                                            <img className="my-6 md:mb-0" src={ infographic.url } alt={infographic.alt} />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            );

        }

        return null;

    }

    return null;

}