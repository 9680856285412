import React, { useState } from "react";
import { PrismicRichText } from "@prismicio/react";
import VideoModal from "../VideoModal";

const VideoCarouselSlice = ({data}:any) => {
  const [isVideoModalShowing, setIsVideoModalShowing] = useState(false);
  const [embedCode, setEmbedCode] = useState(null);
  const [mediaEmbed, setMediaEmbed] = useState(null);
  return (
    <div className="video-carousel bg-gcc-medium-light-gray-2">
      <div className="video-carousel__title text-3xl md:text-4xl-2">
        <PrismicRichText field={data.primary.title} />
      </div>
      <div className="video-carousel__scroll-pane">
        {
          data.items.map(({video_poster_image, video_label, thumbnail_image, embed_code, media_embed}:any, index:number) => (
            <div
              className="video-carousel__lozenge border-gcc-bright-orange border-opacity-30"
              key={`lozenge-${index}`}
            >
              <div
                className="video-carousel__poster"
                style={{backgroundImage: `url('${video_poster_image.url}')`}}
                onClick={() => {
                    if (typeof media_embed.url !== undefined) {
                        setMediaEmbed(media_embed.url);
                    } else {
                        setEmbedCode(embed_code);
                    }
                    setIsVideoModalShowing(true);
                }}
              >
                <img className="video-carousel__control" src='/images/circle-play-solid.svg' alt="Play video button" />
                <div className="video-carousel__overlay">
                  <div className="video-carousel__thumb" style={{backgroundImage: `url(${thumbnail_image.url})`}} />
                  <span className="font-bold leading-6">{video_label}</span>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      {isVideoModalShowing &&
          <VideoModal embedCode={embedCode} mediaEmbed={mediaEmbed} handleCloseRequest={() => setIsVideoModalShowing(false)} />
      }
    </div>
  )
};

export default VideoCarouselSlice;