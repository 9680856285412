import React from "react"
import { textItemsFromField } from "../PrismicUtils";

// c04_benefit_feature_name
const BenefitCard = ({data}:any) => {
    const {c04_benefit_icon, c04_benefit_feature_name, c04_benefit_feature_description} = data;
    return (
      <div className='card md:ml-4 md:w-56 md:h-48 min-h-19 rounded-md bg-white py-2.5 px-4 md:px-0 flex md:flex-col md:justify-end items-center'>
        <div className='symbol md:mb-2'>
          {c04_benefit_icon &&
            <img className="w-11 md:w-14 h-11 md:h-14" alt={c04_benefit_icon.alt} src={c04_benefit_icon.url} />
          }
        </div>
        <div className="ml-4 md:ml-0 md:min-h-5/12">
            {
                textItemsFromField(c04_benefit_feature_name, (text, index) =>
                    <p className="font-bold leading-6 text-base-lg md:text-base md:leading-tight" key={`item-${index}`}>{text}</p>)
            }
            {
                textItemsFromField(c04_benefit_feature_description, (text, index) =>
                    <p className="text-xs md:mt-1" key={`item-${index}`}>{text}</p>)
            }
        </div>
        
      </div>
    )
  }

export default BenefitCard;