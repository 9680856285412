import Prismic from 'prismic-javascript'

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
export const apiEndpoint =  'https://gcc-ab-jb-bca.cdn.prismic.io/api/v2'

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
const accessToken = 'MC5ZZlU3YmhFQUFDWUFyclZY.fjPvv73vv73vv70tYO-_ve-_ve-_vUdbbu-_vSfvv73vv73vv71KPO-_ve-_ve-_vUZH77-9Xe-_vU3vv73vv73vv70'

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  if (doc.type === 'page') return `/${doc.uid}`
  if (doc.type === 'gcc_home_page') return `/${doc.uid}`
  if (doc.type === 'gcc_content_page') return `/${doc.uid}`
  if (doc.type === 'gcc_blog_entry') return `/blog/${doc.uid}`
  return '/'
}

// Client method to query documents from the Prismic repo
export const client = Prismic.client(apiEndpoint, { accessToken })