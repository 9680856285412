import React, { useState } from "react";
import { textItemsFromField } from "../../PrismicUtils";
import PrismicCTAButton from "../PrismicCTAButton";
import { PrismicLink } from "@prismicio/react";

const TestimonialFeatureSetSlice = (props:any) => {

    const [showVideoModal, setShowVideoModal] = useState('');

    const data = props.data;
    const {primary} = data;
    const {items} = data;

    if(primary && items) {

        const {mobile_swap_pane_order} = primary;

        return (
            <div className="px-5">
               
                <div>
                    {
                        items.map(({
                                    title,
                                    subtitle,
                                    body1,
                                    testimonial_title,
                                    testimonial_body,
                                    testimonial_name,
                                    testimonial_position,
                                    testimonial_image,
                                    full_testimonial_link,
                                    button_label,
                                    button_link,
                                    video_embed_snippet
                                }:any,
                                index:number
                            ) => (
                                <div
                                    className={
                                        `mb-16 md:mb-32 md:mx-16 ${
                                            mobile_swap_pane_order?
                                            'flex flex-col-reverse' :
                                            ''
                                        } md:flex md:justify-between md:odd:flex-row md:even:flex-row-reverse`
                                    }
                                    key={`item-${index}`}
                                >
                                    <div
                                        className={
                                            `${
                                                mobile_swap_pane_order?
                                                'mt-8 md:mt-0' :
                                                ''
                                            } md:w-12/25 h-auto md:h-auto border border-solid border-gcc-bright-orange rounded-2xl border-opacity-30 pt-8 px-5 md:px-8 pb-11 md:pb-8 flex flex-col justify-between md:text-lg`
                                        }>
                                        {
                                            textItemsFromField(
                                                testimonial_title,
                                                (text, i) => <p className="leading-7 mb-2 font-bold" key={`item-${i}`}>{text}</p>
                                            )
                                        }
                                        {
                                            textItemsFromField(
                                                testimonial_body,
                                                (text, i) => <p className="leading-7 mb-4" key={`item-${i}`}>{text}</p>
                                            )
                                        }
                                        <div className="flex items-center mt-4">
                                            {
                                                testimonial_image?
                                                    <img
                                                        className="w-20 h-20 rounded-full"
                                                        src={testimonial_image.url} alt={testimonial_image.alt}
                                                    /> :
                                                    <div />
                                            }
                                            <div className="ml-4 text-gcc-orange">
                                                <p className="font-bold leading-4 md:leading-6">{testimonial_name}</p>
                                                { testimonial_position && testimonial_position.length > 0 &&
                                                    <p className="leading-4 md:leading-6 mb-3">{testimonial_position ? testimonial_position[0].text : ''}</p>
                                                }
                                                {
                                                    full_testimonial_link && !video_embed_snippet &&
                                                        <PrismicLink className="underline" document={full_testimonial_link}>View full testimonial</PrismicLink>
                                                }
                                                {
                                                    video_embed_snippet &&
                                                        <div className="underline cursor-pointer" onClick={() => setShowVideoModal(`${index}`)}>
                                                            Watch full video
                                                        </div>
                                                }
                                                {showVideoModal && showVideoModal === index.toString() &&
                                                    <div
                                                    className={`fixed top-0 left-0 z-50 w-screen h-screen bg-gcc-modal-backdrop flex justify-center items-center`}
                                                    
                                                >
                                                    <div className="z-50 w-full h-full md:h-1/2 md:w-1/2 flex flex-col justify-center">
                                                        <div className="absolute top-5 right-5 cursor-pointer" onClick={() => setShowVideoModal(``)}><img className="h-8 w-8" src="/images/close-white.svg" alt=''></img></div>
                                                        <div dangerouslySetInnerHTML={{ __html: video_embed_snippet }}></div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-9 md:mt-0 md:w-12/25">
                                        {
                                            textItemsFromField(
                                                title,
                                                (text, i) =>
                                                    <h1
                                                        className="w-full md:w-auto text-2xl-2 md:text-4xl-2 tracking-tight leading-8 md:leading-tight"
                                                        key={`item-${i}`}
                                                    >
                                                        {text}
                                                    </h1>
                                            )
                                        }
                                        {
                                            textItemsFromField(
                                                subtitle,
                                                (text, i) => <h2 className="mt-3 font-bold" key={`item-${i}`}>{text}</h2>
                                            )
                                        }
                                        {
                                            textItemsFromField(
                                                body1,
                                                (text, i) =>
                                                    <p className="mt-4 md:mt-0.5 md:leading-relaxed" key={`item-${i}`}>
                                                        {text}
                                                    </p>
                                            )
                                        }
                                        {
                                            button_link && button_label &&
                                                <PrismicCTAButton document={button_link} label={button_label}></PrismicCTAButton>
                                        }
                                    </div>
                                </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    return null;
};

export default TestimonialFeatureSetSlice;