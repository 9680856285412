import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import SlicesBlock from "./SlicesBlock";


type Props = {
    document:any;
    sectionClass:string;
};

const PageContent = ({document, sectionClass}:Props) => {

  if(document && document.data && document.data.body) {

        return (
        <div className={sectionClass}>
            

            {/* Header */}
            <Header/>
            {document && document.data && document.data.body && (
                // @ts-ignore
                document.data.body.map((slice, index) => {
                if (slice.slice_type === 'hero_component') {
                    return (
                    <SlicesBlock slice={slice} key={index} />
                    )
                } else {
                    return null;
                }
                })
            )}

        {/* Slices Block */}
        <div>
        {document && document.data && document.data.body && (
            // @ts-ignore
            document.data.body.map((slice, index) => {
                if (slice.slice_type !== 'hero_component') {
                return (
                    <SlicesBlock slice={slice} key={index} />
                )
                } else {
                return null;
                }
            })
        )}
        </div>

        {/* Footer */}
        <Footer/>
       
        </div>);
    }

    return null;
};

export default PageContent;