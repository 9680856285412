import React from "react";
import StandardLinkButton from "./StandardLinkButton";

type Props = {
    menuItems:{label:string, url:string}[];
}

const MenuBar = ({menuItems}:Props) => (

    <div className="flex justify-between">
        <ul className="flex items-center text-lg font-bold">
            {
                menuItems.map(({label, url}, index) => (
                    <li className={`ml-screen-5 ${ window.location.href.includes(url) ? 'text-gcc-bright-orange' : ''} hover:text-gcc-bright-orange`} key={`item-${index}`}>
                        <a href={url}>{label}</a>
                    </li>
                ))
            }
        </ul>
        <div className="w-44 h-11">
                <StandardLinkButton href="/jobs">Join our team</StandardLinkButton>
        </div>
    </div>
);

export default MenuBar;