import React, { Fragment, useState, useEffect } from "react"

import { useSinglePrismicDocument } from '@prismicio/react'
import { PrismicLink } from "@prismicio/react";
import CookiePanel from "./CookiePanel"

const Footer = () => {
    const [document] = useSinglePrismicDocument('gcc_global_footer');
    const [showCookiePanel, setShowCookiePanel] = useState(false);

    // Show the cookie policy on page load
    useEffect(() => {
       if (!window.localStorage.getItem('cookies-accepted')) {
        setShowCookiePanel(true)
       } else {
        setShowCookiePanel(false)
       }
    }, []);

    const onAccept = () => {
        window.localStorage.setItem('cookies-accepted', 'true')
        setShowCookiePanel(false)
    }

    return (
      <div className='footer'>
        {document && (
            <div className="pt-11 md:pt-12 pb-5 text-white text-xs md:text-base">
                    
                {/* Social Media Icons */}
                <div className='social-icons opacity-90'>
                    {document.data && document.data.social_media_icons &&
                        (document.data.social_media_icons as any).map((socialMediaIcons:any, index:number) => {
                        return (
                            socialMediaIcons.link && (
                                <a className="w-8 md:w-10 h-8 md:h-10 ml-8 md:ml-7 first:ml-0" target="_blank" rel="noopener noreferrer" key={index} href={socialMediaIcons.link.url}>
                                    <img alt={socialMediaIcons.name[0].text} src={socialMediaIcons.icon.url} width="50" height="50" />
                                </a>
                            )
                        )
                        })
                    }
                </div>

                {/* Page Links */}
                <div className='links mt-7 md:mt-12'>
                    <ul>
                        {document.data && document.data.page_links &&
                            (document.data.page_links as any).map((pageLink:any, index:number) => {
                            return (
                                <Fragment key={`item-frag-${index}`}>
                                    {index? <li className="mx-2" key={`divider-${index}`}>|</li> : null}
                                    <li key={`link-${index}`}>
                                        {pageLink.page_link && pageLink.text && (
                                            <PrismicLink
                                                document={pageLink.page_link}
                                                externalComponent={
                                                    pageLink.page_link_external?
                                                        (props:any) => <a {...props} target='_blank' rel='noopener noreferrer' /> :
                                                        undefined
                                                }
                                            >
                                                {pageLink.text[0].text}
                                            </PrismicLink>
                                        )}
                                    </li>
                                </Fragment>
                            )
                            })
                        }
                    </ul>
                </div>

                {/* Copyright */}
                {document.data.copyright && (
                    <div className='copyright mt-4 opacity-60 md:text-sm'>
                        {(document.data.copyright as any)[0].text}
                    </div>
                )}
                {
                    showCookiePanel?
                        <CookiePanel onAccept={() => onAccept()} /> :
                        null
                }
            </div>
        )}
      </div>
    )
}

export default Footer;