import React from "react"
import PrismicCTAButton from "../PrismicCTAButton"

const HeroSlice = (props:any) => {
    const {data} = props;
    const primary = data? data.primary : null;
    return (
      primary && (
        <div className="hero mb-10 md:h-screen md:min-h-700 bg-gcc-medium-light-gray-2 md:bg-cover px-5 md:pl-36 pt-24 pb-11 md:flex md:flex-col md:justify-center" style={{backgroundImage: `url(${data.primary.c01_hero_image ? data.primary.c01_hero_image.url : ''})`}}>
            {primary.c01_hero_title && primary.c01_hero_title.map((title:any, index:any) => {
                return (
                    <h1 className="md:w-170 md:leading-tight text-3xl md:text-5xl-2 tracking-tight" key={index}>{title.text}</h1>
                )
            })}
            {primary.c01_hero_text && primary.c01_hero_text.map((text:any, index:any) => {
                return (
                    <p className="md:w-170 md:leading-relaxed font-medium" key={index}>{text.text}</p>
                )
            })}
          {primary.c01_hero_button_link && primary.c01_hero_button_label[0] && (
            <PrismicCTAButton document={primary.c01_hero_button_link} label={primary.c01_hero_button_label[0].text}></PrismicCTAButton>
          )}
        </div>
      )
    )   
  }

export default HeroSlice;